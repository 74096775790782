import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import type { ManualsQuery } from "../../../graphql-types";
import SEO from "../../components/seo";
import Layout from "../../components/layout";
import { HeaderPlaceholder } from "../../styles/ui.styles";

interface Props {
  data: ManualsQuery;
}

const Manuals: React.FC<Props> = ({ data }) => {
  return (
    <>
      <SEO title={"Manuals"} description={"Manuals"} />
      <Layout>
        <HeaderPlaceholder />
        <Wrapper>
          <h1>Manuals</h1>
          {data?.allManualsJson?.edges?.map((node) => {
            // do not show if it is a dummy object
            if (node.node.title?.includes("dummy")) return;
            return (
              <ManualWrapper key={node?.node?.id}>
                <div>
                  <p>{node?.node?.title}</p>
                </div>
                {node?.node?.attachment?.publicURL && (
                  <div>
                    <a
                      href={node.node.attachment.publicURL}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Download
                    </a>
                  </div>
                )}
              </ManualWrapper>
            );
          })}
        </Wrapper>
      </Layout>
    </>
  );
};

export default Manuals;

export const data = graphql`
  query Manuals {
    allManualsJson {
      edges {
        node {
          id
          title
          attachment {
            publicURL
          }
        }
      }
    }
  }
`;

const Wrapper = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 var(--gutter);
  padding-bottom: 100px;
`;

const ManualWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 560px;
  /* border-bottom: 1px solid var(--color-orange); */
  padding: 22px;
  background: #fff;

  border-radius: 22px;
  box-shadow: var(--shadow);
  margin: 25px 0;
  p {
    margin: 0;
  }
`;
